<template>
	<div class="commodity">
		<div class="commodity-main">
			<div class="breadcrumb">
				<el-breadcrumb separator="/">
					<el-breadcrumb-item style="cursor: pointer;" v-for="(item, index) in TypeList" :key="index">
						<span @click="handleNav(item, index)">{{ item.value }}</span>
					</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<div class="commodity-content">
				<el-row :gutter="20">
					<el-col :span="9">
						<div class="commodity-img">
							<div>
								<div class="left">
									<video v-if="isVideo" :src="pic + detail.Video" controls="controls"></video>

									<img class="leftImg" v-if="!isVideo" :src="pic + detail.Picture" alt="" />

									<!-- 鼠标层罩 -->
									<div v-show="topShow" class="top" :style="topStyle"></div>
									<!-- 最顶层覆盖了整个原图空间的透明层罩 -->
									<div class="maskTop" v-if="!isVideo" @mouseenter="enterHandler" @mousemove="moveHandler" @mouseout="outHandler"></div>
								</div>
								<div v-show="rShow" class="right"><img :style="r_img" class="rightImg" :src="pic + detail.Picture" alt="" /></div>
							</div>
						</div>

						<div class="swiper-father">
							<div class="commodity-list">
								<Swiper ref="mySwiper" :options="swiperOption" class="swiper-container">
									<SwiperSlide class="swiper-item" v-for="(item, index) in detail.PhotoList" :key="index">
										<el-image style="width: 80px; height: 80px" :src="pic + item.PicUrl" fit="scale-down" @click="changeImg(item)"></el-image>
									</SwiperSlide>
									<div class="swiper-button-prev swiper_btn" slot="button-prev"></div>
									<div class="swiper-button-next swiper_btn" slot="button-next"></div>
								</Swiper>
							</div>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="title">{{ detail.ProName }}</div>
						<div class="sku">SKU: {{ detail.BarCode }}</div>
						<div class="text">{{ detail.Description }}</div>
						<div class="price">
							<el-row :gutter="20">
								<el-col :span="12">
									<div class="original">
										<div class="original-left">原价</div>
										<div class="original-right">￥{{ detail.InTaxPrice }}</div>
									</div>
									<div class="promotion">
										<div class="promotion-left">促销价</div>
										<div class="promotion-right">￥{{ detail.Price }}</div>
									</div>
									<div v-if="detail.PLUSPrice > 0 && detail.Price != detail.PLUSPrice" class="PLUS">
										<div><img src="../../assets/plus.png" @click="toplus" alt="" /></div>
										<div class="PLUSPrice">￥{{ detail.PLUSPrice }}</div>
									</div>
								</el-col>
								<el-col :span="12">
									<el-row :gutter="20">
										<el-col :span="8">
											<div v-if="!detail.CollState" class="cumulative evaluate" style="cursor: pointer" @click="collection">
												<div><i class="iconfont icontianjiashoucang"></i></div>
												<div class="text">收藏</div>
											</div>
											<div v-else class="cumulative evaluate" style="cursor: pointer" @click="delCollection">
												<div><i class="iconfont icontianjiashoucang" style="color: #DC2310"></i></div>
												<div class="text">已收藏</div>
											</div>
										</el-col>
										<el-col :span="8">
											<div class="cumulative evaluate" style="padding-right: 8px; cursor: pointer" @click="toEvaluate">
												<div class="number">{{ detail.EvSum }}</div>
												<div class="text">累计评价</div>
											</div>
										</el-col>
										<el-col :span="8">
											<div class="cumulative">
												<div class="number">{{ detail.VirtualSales }}</div>
												<div class="text">累计售出</div>
											</div>
										</el-col>
									</el-row>
								</el-col>
								<el-col :span="24">
									<div class="coupon">
										<div class="coupon-left">优惠券</div>
										<div class="coupon-right">
											<div v-for="(item, index) in disList" :key="index">
												<div
													:class="[
														'button',
														{
															active: item.State != 1
														}
													]"
													v-if="item.MinPrice > 0"
													@click="handleAddCoupon(item)"
												>
													满{{ item.MinPrice }}减{{ item.Money }}
												</div>
												<div
													v-else
													:class="[
														'button',
														{
															active: item.State != 1
														}
													]"
													@click="handleAddCoupon(item)"
												>
													通用{{ item.Money }}
												</div>
											</div>
										</div>
									</div>
								</el-col>
							</el-row>
						</div>
						<div class="seivice">
							<div class="seivice-left">服务承诺</div>
							<div class="seivice-right">
								<div class="seivice-right-item">
									<div><i class="iconfont iconservicequanguolianbao"></i></div>
									<div>全国联保</div>
								</div>
								<div class="seivice-right-item">
									<div><i class="iconfont iconfapiaoguanli"></i></div>
									<div>{{ detail.TaxRate * 100 }}% 增值税发票</div>
								</div>
								<div class="seivice-right-item">
									<div><i class="iconfont iconservicebaoyouqia"></i></div>
									<div>全场包邮</div>
								</div>
							</div>
						</div>
						<div class="border"></div>
						<div class="purchase">
							<div class="purchase-left">购买数量</div>
							<div class="purchase-right">
								<div><el-input-number size="mini" v-model="value" :min="1" :max="detail.Stock" label="描述文字"></el-input-number></div>
								<div class="purchase-number">
									<div class="nervous" v-if="detail.Stock < 10">库存紧张</div>
									<div class="adequate" v-else>库存充足</div>
								</div>
							</div>
						</div>
						<div></div>
						<div class="handle" v-if="detail.State == 1 && detail.IsActivity == true">
							<div @click="handleImmediately" style="background-color:#0197ba;">立即购买</div>
							<div class="cart" @click="handleAddCart" style="background-color:#80c4d6;">加入购物车</div>
							<div class="cart" @click="toChat" style="background-color:#c0d96d;">联系客服</div>
						</div>
						<div class="handle" v-else><div class="xj">商品已下架</div></div>
					</el-col>
				</el-row>
			</div>
			<div class="tab">
				<div>
					<span v-for="(item, index) in tab" :key="index" :class="item.active ? 'active' : ''" @click="changeTab(index)">{{ item.title }}</span>
				</div>
				<el-popover class="popover" placement="left-start" width="100" trigger="hover" title="" content="">
					<div slot="reference" class="phone">
						手机扫码
						<i class="iconfont iconerweima"></i>
					</div>
					<vue-qr :text="downloadData.url" :margin="10" colorDark="#000" colorLight="#fff" :dotScale="1" :logoSrc="downloadData.icon" :logoScale="0.2" :size="150"></vue-qr>
				</el-popover>
			</div>
			<div class="tab-content">
				<div class="details" v-if="index == 0">
					<div>
						<div style="display: flex;padding-left:10px">
							<div class="details-bottom-item">
								<span>品牌：</span>
								<span>HEXAGON</span>
							</div>
							<div class="details-bottom-item">
								<span>商品型号：</span>
								<span>{{ detail.ProName }}</span>
							</div>
							<div class="details-bottom-item">
								<span>商品编号：</span>
								<span>{{ detail.BarCode }}</span>
							</div>
						</div>
					</div>
					<div class="details-border"></div>
					<div class="details-html" ref="detailsHtml" v-html="detail.Detail"></div>
				</div>
				<div class="evaluate" v-if="index == 1">
					<div class="evaluate-top">
						<div class="evaluate-title">大家都写到 :</div>
						<div class="evaluate-item">
							<div v-for="(item, index) in evTip" :key="index" @click="changeEvaluate(item, index)" :class="item.active ? 'active' : ''">{{ item.Tip }}</div>
						</div>
					</div>
					<div class="border"></div>
					<div class="evaluate-main">
						<el-row v-for="(item, index) in evaluateList" :key="index" class="evaluate-list">
							<el-col :span="4">
								<div class="evaluate-user">
									<div>
										<el-avatar v-if="!item.Pictrue" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
										<el-avatar v-else :src="item.Pictrue"></el-avatar>
									</div>
									<div class="evaluate-user-name">{{ item.UserName }}</div>
								</div>
								<div class="evaluate-date">{{ item.CreatTime }}</div>
							</el-col>
							<el-col :span="20">
								<div class="evaluate-rate"><el-rate disabled v-model="item['LogisticsStar']"></el-rate></div>
								<div class="evaluate-text">{{ item.Detail }}</div>
								<div>
									<el-image v-for="(item, index) in item.Photos" :key="index" class="evaluate-img" :src="pic + item" :preview-src-list="[pic + item]" fit="fill"></el-image>
								</div>
							</el-col>
						</el-row>
						<div class="pagination">
							<div>
								<el-pagination
									background
									@size-change="changeSize"
									@current-change="changeCurrent"
									:page-sizes="[10, 50, 100, 200]"
									:page-size="20"
									layout="total, sizes, prev, pager, next, jumper"
									:total="count"
								></el-pagination>
							</div>
						</div>
					</div>
				</div>
				<div class="parameter" v-if="index == 2">
					<div>
						<div class="list-pro-bottom">
							<div class="wrap" v-for="(j, i) in detail.Condition" :key="i">{{ j.CName }}</div>
						</div>
						<div class="list-pro-bottom">
							<div class="wrap" v-for="(j, i) in detail.Condition" :key="i">{{ j.DetailValue }}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="recommend">
				<i class="iconfont icontuijian"></i>
				<span>推荐商品</span>
			</div>
			<div class="recommend-main">
				<el-row :gutter="20">
					<el-col :span="6" v-for="(item, index) in recommendList" :key="index">
						<div class="commodity-list" @click="handleToPage(item)">
							<el-card class="commodity-card" shadow="never" :body-style="{ padding: '10px' }"><img :src="pic + item.Picture" class="image" style="height:250px" /></el-card>
							<div class="commodity-text">
								<div class="name">{{ item.ProName }}</div>
								<div class="bottom">
									<div class="bottom-top">
										<div class="price">￥{{ item.Price }}</div>
										<div v-if="item.PLUSPrice > 0 && item.Price != item.PLUSPrice" class="PLUS">
											￥{{ item.PLUSPrice }}
											<img src="../../assets/plus.png" @click="toplus" alt="" />
										</div>
									</div>
									<div class="bottom-bottom">
										<div>
											<span class="original">￥{{ item.InTaxPrice }}</span>
										</div>
										<div class="sold">已售{{ item.VirtualSales }}件</div>
									</div>
								</div>
								<div class="border"></div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<!--加入购物车-->
		<el-dialog :visible.sync="cartVisible" width="500px">
			<div class="cardDialog">
				<i class="iconfont icongouwucheman"></i>
				<div class="title">商品已成功加入购物车！</div>
				<div class="data">
					<el-row>
						<el-col :span="8" class="data-left"><img :src="pic + detail.Picture" alt="" /></el-col>
						<el-col :span="12" class="data-center">
							<div class="proName">{{ detail.ProName }}</div>
							<div class="description">{{ detail.Description }}</div>
						</el-col>
						<el-col :span="4" class="data-number">× {{ value }}</el-col>
					</el-row>
				</div>
				<div class="button">
					<div class="view" @click="viewCart">查看购物车</div>
					<div class="continue" @click="cartVisible = false">继续购物</div>
				</div>
			</div>
		</el-dialog>
		<!--领取优惠卷-->
		<el-dialog :visible.sync="couponVisible" width="400px">
			<div class="couponVisible">
				<div class="couponVisible-top">
					<div class="couponVisible-top-left"><i class="iconfont iconduihao"></i></div>
					<div class="couponVisible-top-right">优惠券领取成功！</div>
				</div>
				<div class="tips">领取成功 结算可以享有优惠价啦</div>
				<div class="button" @click="couponVisible = false">继续购物</div>
			</div>
		</el-dialog>
	</div>
</template>

<script>
import { Swiper as SwiperClass, Pagination, Navigation } from 'swiper/core';
import getAwesomeSwiper from 'vue-awesome-swiper/dist/exporter';
import vueQr from 'vue-qr';
import 'swiper/swiper-bundle.min.css';
import qs from 'qs';
import { getProductDetail, getDisList, receiveCoupon, getProductEvTip, getProductEvaluate, addCart, getRecommend } from './service';
import { addCollect } from '../cart/service';
import { deleteCollect } from '../collection/service';

const { Swiper, SwiperSlide } = getAwesomeSwiper(SwiperClass);
SwiperClass.use([Pagination, Navigation]);
export default {
	components: {
		Swiper,
		SwiperSlide,
		vueQr
	},
	data() {
		return {
			topStyle: { transform: '' },
			r_img: {},
			topShow: false,
			rShow: false,
			pic: FILE_URL,
			count: 0,
			detail: [],
			disList: [],
			recommendList: [],
			TypeList: [],
			downloadData: {
				url: ''
			},
			evTip: [],
			isVideo: false,
			cartVisible: false,
			PageIndex: 1,
			PageSize: 10,
			couponVisible: false,
			value: 1,
			num: 1,
			parameter: {
				Tips: '',
				PageIndex: 1,
				PageSize: 10
			},
			index: 0,
			evaluateList: [],
			evaluate: [
				{
					title: '全部 (450)',
					active: true
				}
			],
			tab: [
				{
					title: '商品详情',
					active: true
				},
				{
					title: '商品评价 (203) ',
					active: false
				},
				{
					title: '产品参数',
					active: false
				}
			],
			swiperOption: {
				slidesPerView: 5,
				spaceBetween: 2,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev'
				}
			}
		};
	},
	mounted() {
		this.init();
		this.downloadData.url = `http://mshop.hexagonmi.com.cn/#/goods-details?goodsId=${this.$route.query.pid}`; //需要转化成二维码的网址
	},
	watch: {
		$route(to, from) {
			//监听路由是否变化
			if (to.query.pid != from.query.pid) {
				this.init(); //重新加载数据'
			}
		}
	},
	methods: {
		toChat(order) {
			window.open('https://mcs.hexagonmetrology.cn/im/text/0hnzjp.html')
			// this.ysf('product', {
			// 	show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
			// 	title: this.detail.ProName,
			// 	desc: this.detail.Description,
			// 	picture: FILE_URL + this.detail.Picture,
			// 	note: '价格：￥' + this.detail.Price,
			// 	url: window.location.href,
			// 	success: () => {
			// 		// 成功回调
			// 		this.ysf('open');
			// 	},
			// 	error: () => {
			// 		// 错误回调
			// 		this.ysf('open');
			// 	}
			// });
		},
		// 鼠标进入原图空间函数
		enterHandler() {
			// 层罩及放大空间的显示
			this.topShow = true;
			this.rShow = true;
		},
		// 鼠标移动函数
		moveHandler(event) {
			// 鼠标的坐标位置
			let x = event.offsetX;
			let y = event.offsetY;
			// 层罩的左上角坐标位置，并对其进行限制：无法超出原图区域左上角
			let topX = x - 100 < 0 ? 0 : x - 100;
			let topY = y - 100 < 0 ? 0 : y - 100;
			// 对层罩位置再一次限制，保证层罩只能在原图区域空间内
			if (topX > 250) {
				topX = 250;
			}
			if (topY > 250) {
				topY = 250;
			}
			// 通过 transform 进行移动控制
			this.topStyle.transform = `translate(${topX}px,${topY}px)`;
			this.r_img.transform = `translate(-${2 * topX}px,-${2 * topY}px)`;
		},
		// 鼠标移出函数
		outHandler() {
			// 控制层罩与放大空间的隐藏
			this.topShow = false;
			this.rShow = false;
		},
		changeSize(size) {
			this.parameter.PageSize = size;
			this.asyncgetProductEvaluate();
		},
		changeCurrent(size) {
			this.parameter.PageIndex = size;
			this.asyncgetProductEvaluate();
		},
		handleToPage(data) {
			let u = localStorage.getItem('u');
			window.open(`/commodity?pid=${data.ID}&u=${u}`, '_blank').location;
		},
		toEvaluate() {
			this.index = 1;
			for (const tabKey in this.tab) {
				this.tab[tabKey].active = false;
			}
			this.tab[1].active = true;
			document.documentElement.scrollTop = 500;
		},
		delCollection() {
			if (!localStorage.getItem('userToken')) {
				this.toCIAMLoginIn('请先登录！');
				// this.$message.error('请先登录！');
				// localStorage.setItem('u', '');
				// setTimeout(() => {
				// 	let url = encodeURI(window.location.href);
				// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
				// }, 1000);
				return;
			}
			deleteCollect({
				PID: this.$route.query.pid
			}).then(() => {
				this.$message.success('取消成功');
				this.init();
			});
		},
		collection() {
			if (!localStorage.getItem('userToken')) {
				this.toCIAMLoginIn('请先登录！');
				// this.$message.error('请先登录！');
				// localStorage.setItem('u', '');
				// setTimeout(() => {
				// 	let url = encodeURI(window.location.href);
				// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
				// }, 1000);
				return;
			}
			addCollect({
				PID: this.$route.query.pid
			}).then(() => {
				this.$message.success('收藏成功');
				this.init();
			});
		},
		init() {
			this.pid = this.$route.query.pid;
			// 获取商品详情
			getProductDetail({ PID: this.pid }).then(res => {
				this.detail = res.data;
				this.BarCode = res.data.BarCode;

				if (res.data.Video && res.data.Video != '') {
					this.isVideo = true;
				}
				this.tab[1].title = `商品评价( ${this.detail.EvSum} )`;
				this.TypeList = res.data.TypeList;
				this.TypeList.push({
					ID: '',
					label: '',
					value: this.detail.ProName
				});
			});
			// 优惠卷
			let login = localStorage.getItem('userToken');
			let parameter = {
				PID: this.pid
			};
			if (login) {
				parameter.UserID = localStorage.getItem('userToken');
			} else {
				// localStorage.setItem('u', '');
				this.clearLocalStorage();
			}
			getDisList(parameter).then(res => {
				this.disList = res.data;
			});

			// 获取商品评价标签
			getProductEvTip({
				PID: this.pid
			}).then(res => {
				this.evTip = res.data;
				this.evTip.unshift({
					Tip: '全部',
					active: true
				});
			});
			this.asyncgetProductEvaluate();
			// 获取推荐商品
			getRecommend({
				PID: this.pid
			}).then(res => {
				this.recommendList = res.data;
			});
		},
		// 轮播图点击
		changeImg(data) {
			this.isVideo = false;
			this.detail.Picture = data.PicUrl;
		},
		asyncgetProductEvaluate() {
			// 获取商品评价列表
			getProductEvaluate({
				PID: this.pid,
				PageIndex: this.parameter.PageIndex,
				PageSize: this.parameter.PageSize,
				Tips: this.parameter.Tips
			}).then(res => {
				this.count = res.count;
				this.evaluateList = res.data;
				for (const evaluateListKey in this.evaluateList) {
					if (this.evaluateList[evaluateListKey].Photos && this.evaluateList[evaluateListKey].Photos != '') {
						this.evaluateList[evaluateListKey].Photos = this.evaluateList[evaluateListKey].Photos.split(',');
					}
				}
			});
		},
		// 立即购买
		handleImmediately() {
			// let isLogin = localStorage.getItem('userToken');
			if (this.$store.state.isLogin) {
				this.$router.push({
					path: `/settlement?data=${this.pid}|${this.value}&u=${this.$route.query.u}`
				});
			} else {
				this.toCIAMLoginIn('请先登录！');
				// localStorage.setItem('u', '');
				// this.$message.error('请先登录！');
				// setTimeout(() => {
				// 	let url = encodeURI(window.location.href);
				// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
				// }, 1000);
				return;
			}
		},

		// 加入购物车
		handleAddCart() {
			// 已经登录执行接口
			// let isLogin = localStorage.getItem('userToken');
			if (this.$store.state.isLogin) {
				addCart({
					PID: this.pid,
					Count: this.value
				}).then(() => {});
				this.cartVisible = true;
				this.$store.commit('add');
				return;
			} else {
				// localStorage.setItem('u', '');
				this.clearLocalStorage();
			}
			// 未登录添加缓存

			this.setCartStorage([
				{
					code: this.BarCode,
					count: this.value
				}
			]);

			this.$store.commit('add');
			this.cartVisible = true;
		},
		// 加入购物车弹窗关闭收藏
		handleClose() {
			this.cartVisible = false;
		},
		toplus() {
			if (!localStorage.getItem('userToken')) {
				this.toCIAMLoginIn();
				// localStorage.setItem('u', '');
				// this.$message.success('正在跳转登录');
				// setTimeout(() => {
				// 	let url = encodeURI(window.location.href);
				// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
				// }, 1000);
			} else {
				window.open(`/me/plus`, '_blank').location;
			}
		},
		// 领取优惠卷
		handleAddCoupon(data) {
			if (data.State == 2) {
				window.open(`/me/coupon`, '_blank').location;
				return;
			}
			// let isLogin = localStorage.getItem('userToken');
			if (!this.$store.state.isLogin) {
				this.toCIAMLoginIn('请先登录！');
				// this.$message.error('请先登录！');
				// setTimeout(() => {
				// 	let url = encodeURI(window.location.href);
				// 	window.location.href = `${URL_LOGIN}?returnurl=${url}`;
				// }, 1000);
				return;
			}
			// 登录
			let parameter = {
				DisID: data.ID
			};
			if (data.State == 1) {
				receiveCoupon(parameter).then(() => {
					this.couponVisible = true;
					this.$message.success('领取成功');
					this.init();
				});
			}
		},
		// 优惠卷关闭
		handleCloseCoupon() {
			this.couponVisible = false;
		},
		handleNav(data, index) {
			if (data.value == '首页') {
				window.location.href = `/`;
			} else {
				let obj = {};
				if (index + 1 != this.TypeList.length) {
					let arr = this.TypeList.slice(0, index + 1);
					for (const key in arr) {
						if (arr[key].label != '') {
							obj[arr[key].label] = arr[key].ID;
						}
					}
				}
				obj.type = 6;
				window.location.href = `/product-list?${qs.stringify(obj)}`;
			}
		},
		changeEvaluate(data, index) {
			for (const tabKey in this.evTip) {
				this.evTip[tabKey].active = false;
			}
			this.evTip[index].active = true;
			if (data.ID) {
				this.parameter.Tips = data.ID;
			} else {
				this.parameter.Tips = '';
			}
			this.asyncgetProductEvaluate();
		},
		changeTab(index) {
			this.index = index;
			for (const tabKey in this.tab) {
				this.tab[tabKey].active = false;
			}
			this.tab[index].active = true;
		},
		// 购物车跳转
		viewCart() {
			let cartList = '';

			if (!localStorage.getItem('userToken')) {
				// localStorage.setItem('u', '');
				this.clearLocalStorage();
				cartList = this.getCartUrl();
			} else {
				cartList = '';
			}
			this.$router.push({
				path: `/cart?data=${cartList}&u=${this.$route.query.u}`
			});
		}
	}
};
</script>

<style lang="less" scoped>
.details-bottom-item {
	margin-right: 30px;
}

.pagination {
	display: flex;
	margin-top: 30px;
	justify-content: flex-end;
	margin-right: 30px;
}

.list-pro-bottom {
	display: flex;
	flex-direction: row;

	.wrap {
		flex: 1;
		color: #666666;
		text-align: center;
		padding: 10px 0;
		border-bottom: 1px solid #dcdcdc;
	}
}

.commodity-main {
	width: 1240px;
	margin: 30px auto;

	.position {
		color: #0097ba;
	}

	.commodity-content {
		margin-top: 30px;

		.cumulative {
			font-size: 13px;

			.number {
				color: #0097ba;
				font-family: 'ct';
			}

			.text {
				color: #666666;
			}

			text-align: center;
		}

		.evaluate {
			border-right: 1px solid #cccccc;
		}

		.seivice {
			margin: 20px 0px;
			display: flex;

			.seivice-left {
				font-family: 'ct';
			}

			.seivice-right {
				display: flex;
				margin-left: 20px;
				color: #666666;

				.seivice-right-item {
					display: flex;
					margin-right: 20px;

					div {
						margin: 0 5px;
					}

					i {
						color: #76c3d4;
						font-size: 22px;
					}
				}
			}
		}

		.border {
			height: 1px;
			background: #f4f4f4;
		}

		.purchase {
			display: flex;
			margin: 20px 0;

			.purchase-left {
				margin-top: 2px;
				font-family: 'ct';
			}

			.purchase-right {
				margin-left: 10px;
				display: flex;
			}

			.purchase-number {
				> div {
					background: rgba(187, 191, 190, 0);
					color: #dc2310;
					font-size: 12px;
					height: 20px;
					line-height: 20px;
					padding: 0 15px;
					margin-left: 10px;
					margin-top: 3px;
				}

				.adequate {
					color: #0097ba;
				}
			}
		}

		.handle {
			color: #ffffff;
			margin-top: 30px;
			display: flex;

			.xj {
				cursor: auto;
				background: #ccc;
			}

			div {
				width: 180px;
				height: 40px;
				text-align: center;
				line-height: 40px;
				background: #0097ba;
				margin-right: 20px;
				cursor: pointer;
			}

			.cart {
				background: #262626;
			}
		}

		.commodity-img {
			border: 1px solid #eee;
			width: 100%;
			height: 452px;
			width: 100%;

			img {
			}
		}

		.commodity-list {
			width: 100%;
			margin-top: 10px;
			box-sizing: border-box;
		}

		.swiper-item {
			cursor: pointer;

			img {
				width: 80px;
				height: 80px;
			}
		}

		.title {
			font-family: 'ct';
			font-size: 18px;
			color: #333333;
		}

		.sku {
			width: fit-content;
			padding: 2px 10px;
			background: #f5f5f5;
			border: 1px solid #dedede;
			border-radius: 15px;
			color: #333333;
			font-size: 13px;
			margin: 10px 0px;
		}

		.text {
			font-size: 13px;
			color: #666666;
			margin-top: 3px;
		}

		.price {
			margin-top: 20px;
			background: #f4f4f4;
			padding: 20px 20px;

			.original {
				display: flex;
				color: #666666;

				.original-left {
					font-size: 13px;
					width: 60px;
				}

				.original-right {
					text-decoration: line-through;
				}
			}

			.promotion {
				display: flex;
				color: #666666;
				margin: 5px 0;

				.promotion-left {
					font-size: 13px;
					width: 60px;
					margin-top: 8px;
				}

				.promotion-right {
					font-family: 'ct';
					color: #0097ba;
					font-size: 19px;
				}
			}

			.coupon {
				display: flex;
				color: #666666;
				margin: 5px 0;
				font-size: 13px;

				.coupon-left {
					font-size: 13px;
					width: 60px;
					margin-top: 20px;
				}

				.coupon-right {
					display: flex;
					margin-top: 8px;
					flex-wrap: wrap;

					.button {
						flex: 1;
						background: rgba(220, 35, 16, 0);
						border: 1px solid #dc2310;
						margin-right: 10px;
						padding: 2px 5px;
						color: #dc2310;
						cursor: pointer;
						margin-top: 10px;
					}

					.active {
						background: rgba(220, 35, 16, 0.15);
					}
				}
			}
		}
	}
}

.tab {
	margin-top: 50px;
	background: #e8f0f3;
	padding: 8px 20px;
	display: flex;
	justify-content: space-between;
	font-size: 13px;

	span {
		margin-right: 20px;
		cursor: pointer;
	}

	.active {
		color: #0097ba;
	}

	.phone {
		i {
			font-size: 14px;
		}
	}
}

.tab-content {
	.details {
		padding: 30px 0;
		color: #666666;
		font-size: 13px;
	}

	.details-bottom {
		margin-top: 20px;
		display: flex;
	}

	.parameter {
		margin-bottom: 50px;

		.parameter-list {
			display: flex;
		}

		.border {
			height: 1px;
			background: #cccccc;
		}
	}

	.evaluate {
		padding: 50px 0px;

		.border {
			height: 1px;
			background: #cccccc;
			margin: 30px 0;
		}

		.evaluate-user {
			display: flex;

			div {
				height: 40px;
				line-height: 40px;
			}

			.evaluate-user-name {
				margin-left: 10px;
				font-size: 12px;
				color: #333333;
				width: 100px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}

		.evaluate-date {
			width: 150px;
			margin-top: 10px;
			font-size: 12px;
			color: #999999;
			margin-left: 5px;
		}

		.evaluate-rate {
			margin-top: 5px;
		}

		.evaluate-text {
			font-size: 12px;
			margin: 10px 0;
		}

		.evaluate-img {
			width: 100px;
			height: 100px;
			margin-right: 10px;
			border: 1px solid #eeeeee;
			cursor: pointer;
		}

		.evaluate-list {
			margin-top: 30px;
		}
	}

	.evaluate-top {
		display: flex;
		font-size: 13px;

		.evaluate-title {
			margin-top: 5px;
		}

		.evaluate-item {
			display: flex;

			div {
				margin-left: 20px;
				border: 1px solid #eeeeee;
				color: #999999;
				padding: 3px 10px;
				font-size: 12px;
				cursor: pointer;
			}

			.active {
				color: #ffffff;
				background: #1b98aa;
			}
		}
	}
}

.details-border {
	width: 100%;
	height: 1px;
	margin: 20px 0;
	background: #dcdcdc;
}

.recommend {
	background: #f4f4f4;
	padding: 5px 10px;

	i {
		color: #0097ba;
		font-size: 16px;
	}

	span {
		color: #666666;
		margin-left: 10px;
	}
}
.swiper-father {
	height: 100px;
}
.couponVisible {
	text-align: center;

	.couponVisible-top {
		display: flex;
		justify-content: center;

		.couponVisible-top-left {
			border: 2px solid #4fcb4f;
			width: 30px;
			height: 30px;
			text-align: center;
			line-height: 30px;
			color: #4fcb4f;
			border-radius: 50px;

			i {
				font-size: 22px;
			}
		}

		.couponVisible-top-right {
			font-family: 'ct';
			font-size: 18px;
			margin-top: 2px;
			margin-left: 20px;
		}
	}

	.tips {
		margin: 20px 0;
	}

	.button {
		width: fit-content;
		color: #ffffff;
		background: #0097ba;
		margin: 0 20px;
		padding: 8px 20px;
		cursor: pointer;
		background: #0097ba;
		margin: 0 auto;
	}
}

.recommend-main {
	.commodity-card {
		cursor: pointer;

		img {
			width: 100%;
		}
	}

	.commodity-text {
		margin-top: 20px;

		.code {
			font-size: 14px;
		}

		.name {
			font-size: 14px;
			font-family: 'nl';
		}

		.sku {
			margin: 8px 0;
			font-size: 12px;
			color: #333333;
		}

		.bottom {
			.bottom-top {
				display: flex;

				.price {
					color: #1b98aa;
					font-family: 'ct';
					font-size: 18px;
				}

				.PLUS {
					color: #000000;
					font-family: 'ct';
					font-size: 18px;
					margin-left: 10px;
					margin-top: 0;
				}
			}

			.bottom-bottom {
				display: flex;
				justify-content: space-between;

				.original {
					color: #666666;
					text-decoration: line-through;
				}

				.sold {
					color: #666666;
				}
			}
		}

		.border {
			height: 2px;
			margin-top: 10px;
			background: #bbbfbe;
		}
	}

	.commodity-list {
		position: relative;
		height: 440px;

		.star {
			width: 30px;
			height: 30px;
			line-height: 30px;
			position: absolute;
			top: 20px;
			right: 20px;
			text-align: center;
			background: #eeeeee;
			border-radius: 5px;
			display: none;

			img {
				width: 15px;
				height: 15px;
				margin-top: 7px;
			}
		}
	}

	.commodity-list:hover {
		.border {
			height: 2px;
			background: linear-gradient(50deg, #60b689, #00b5c2, #0897b4);
		}

		.star {
			display: block;
		}
	}
}

video {
	height: 450px;
	width: 450px;
	object-fit: fill;
}

.cardDialog {
	text-align: center;

	i {
		font-size: 56px;
		color: #aad4ff;
	}

	.title {
		margin-top: 20px;
		font-family: 'ct';
		font-size: 16px;
	}

	.data {
		width: 450px;
		margin: 20px 10px;
		background: #f9f9f9;

		img {
			width: 100px;
			height: 100px;
		}

		.data-left {
			padding: 10px 10px 10px 30px;
			box-sizing: border-box;
		}

		.data-center {
			color: #333333;
			margin-top: 10px;
			text-align: left;

			.proName {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			.description {
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
			}
		}

		.data-number {
			font-size: 13px;
			color: #333333;
			margin-top: 10px;
		}
	}

	.button {
		display: flex;
		justify-content: center;

		div {
			color: #ffffff;
			background: #0097ba;
			margin: 0 20px;
			padding: 8px 20px;
			cursor: pointer;
		}

		.continue {
			background: #ffffff;
			border: 1px solid #0097ba;
			color: #0097ba;
		}
	}
}

.PLUS {
	display: flex;
	margin-top: 10px;

	img {
		margin-top: 5px;
	}
}

.PLUSPrice {
	margin-left: 20px;
	font-family: 'ct';
	font-size: 16px;
}
</style>
<style scoped>
>>> .el-rate__icon {
	margin-right: 0px;
}

.swiper-container {
	padding: 0 30px;
}

.swiper-button-prev,
.swiper-button-next {
	color: #ffffff;
	opacity: 0.5;
	font-size: 12px;
}

.swiper-button-prev {
	left: 0;
}

.swiper-button-next {
	right: 0;
}

.swiper-button-next:after,
.swiper-button-prev:after {
	color: #c0c4cc;
	font-weight: 800;
	font-size: 22px;
}

/* 放大的图片，通过定位将左上角定位到(0,0) */
.rightImg {
	display: inline-block;
	width: 800px;
	height: 800px;
	position: absolute;
	top: 0;
	left: 0;
}

/* 右边的区域图片放大空间 */
.right {
	margin-left: 412px;
	width: 452px;
	height: 450px;
	border: 1px solid #cccccc;
	background: #ffffff;
	position: absolute;
	top: 0;
	left: 70px;
	overflow: hidden;
	z-index: 2000;
}

/* 一个最高层层罩 */
.maskTop {
	width: 452px;
	height: 400px;
	position: absolute;
	z-index: 1;
	top: 0;
	left: 0;
}

/* 层罩，通过定位将左上角定位到(0,0) */
.top {
	width: 200px;
	height: 200px;
	background-color: #666666;
	opacity: 0.4;
	position: absolute;
	top: 0;
	left: 0;
}

/* 原图的显示 */
.leftImg {
	width: 452px;
	height: 400px;
	display: inline-block;
}

/* 原图的容器 */
.left {
	width: 452px;
	height: 400px;
	float: left;
	position: relative;
}
</style>
